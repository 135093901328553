<template>
    <div class="placeholderScreen flex-align-center" style="color: aqua;">
        <img src="../../../assets/datascreen/zanwusz.png" alt="" draggable="false">
    </div>
</template>

<script>
export default {
    //import引入组件才能使用
    components: {},
    props: {},
    data() {
        return {

        };
    },
    // 计算属性
    computed: {},
    // 监听data中的数据变化
    watch: {},
    // 方法集合
    methods: {

    },
    // 生命周期，创建完成时（可以访问当前this实例）
    created() {

    },
    // 生命周期：挂载完成时（可以访问DOM元素）
    mounted() {

    },
    beforeCreate() { },//生命周期：创建之前
    beforeMount() { },//生命周期：挂载之前
    beforeUpdate() { },//生命周期：更新之前
    updated() { },//生命周期：更新之后
    beforeDestroy() { },//生命周期：销毁之前
    destroyed() { },//生命周期：销毁完成
    activated() { },//如果页面有keep-alive缓存功能，这个函数会触发执行
}
</script>
<style scoped lang="less">
@import '../../../less/Screen.less';
.placeholderScreen {
    img {
        width: auto;
        .VH(154);
    }
}
</style>